import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faFileText, faLink } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import resume  from "../assets/anish_shilpakar_resume.pdf"

export default function Body() { 
    return (
        <div className="card-body">
            <h2 className="name"> Anish Shilpakar</h2>
            <span className="title">Data Engineer</span>
            <a href="https://twitter.com/anish_shilpakar" className="username">@anish_shilpakar</a>
            <div className="btn-container">

                    <a href="mailto:anishshilpakar8@gmail.com" className="btn email-btn">
                    <FontAwesomeIcon icon={ faEnvelope}className="email-icon"/>
                    Email
                    </a>
                <a href={ resume } download="anish_shilpakar_resume" className="btn linked-btn">
                    <FontAwesomeIcon icon={faFileText} className="resume-icon"/>
                        Resume
                    </a>
            </div>
            <div className="about">
                <h2 className="content-title"> About Me</h2>
                <p className="content-desc">I am an enthusiastic learner highly interested in Data Engineering, AI and NLP. I am currently working as a Data Engineer at Genese Solution. If you wan't to collab on projects let's talk.</p>
            </div>
            <div className="skill">
                <h2 className="content-title">Skills</h2>
                <p className="content-desc">Python with data science libraries,
                and ML/DL frameworks. AWS Cloud, Linux, Git, SQL, MongoDB, Apache Airflow, Knowledge of Hadoop ecosystem and big data tools. JavaScript with React and Node Framework C, C++, PHP, Laravel, Flutter. Proficient in English, Nepali, Newari, Hindi.
                </p>
            </div>
        </div>
    );
}
